import React from "react"
import { PageProps } from "gatsby"
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts"

import Layout from "@components/Layout"
import SEO from "@components/SEO"
import { useTranslation, useQuery } from "@hooks"

import Section from "react-bulma-components/lib/components/section"
import Container from "react-bulma-components/lib/components/container"
import Heading from "react-bulma-components/lib/components/heading"

const Stats = (props: PageProps) => {
  const [t] = useTranslation()

  const response = useQuery("adminStats", {})

  if (!response.data) return null

  // USERS

  const users = [
    {
      name: "users",
      total: response.data.users.total,
    },
    {
      name: "status",
      active: response.data.users.active,
      frozen: response.data.users.frozen,
      inactive: response.data.users.inactive,
      instagram: response.data.users.instagram,
      paid: response.data.users.paid,
    },
    {
      name: "artists",
      total: response.data.users.artists.total,
      unverified: response.data.users.artists.unverified,
      verified: response.data.users.artists.verified,
    },
    {
      name: "collectors",
      total: response.data.users.collectors.total,
    },
    {
      name: "studios",
      total: response.data.users.studios.total,
      unverified: response.data.users.studios.unverified,
      verified: response.data.users.studios.verified,
    },
  ]

  // BOOKINGS

  const bookings = [
    {
      name: "bookings",
      total: response.data.bookings.total,
    },
    {
      name: "guestInvites",
      accepted: response.data.bookings.guestInvites.accepted,
      cancelled: response.data.bookings.guestInvites.cancelled,
      completed: response.data.bookings.guestInvites.completed,
      pending: response.data.bookings.guestInvites.pending,
      rejected: response.data.bookings.guestInvites.rejected,
      total: response.data.bookings.guestInvites.total,
    },
    {
      name: "guestRequests",
      accepted: response.data.bookings.guestRequests.accepted,
      cancelled: response.data.bookings.guestRequests.cancelled,
      completed: response.data.bookings.guestRequests.completed,
      pending: response.data.bookings.guestRequests.pending,
      rejected: response.data.bookings.guestRequests.rejected,
      total: response.data.bookings.guestRequests.total,
    },
    {
      name: "residentRequests",
      accepted: response.data.bookings.residentRequests.accepted,
      cancelled: response.data.bookings.residentRequests.cancelled,
      completed: response.data.bookings.residentRequests.completed,
      pending: response.data.bookings.residentRequests.pending,
      rejected: response.data.bookings.residentRequests.rejected,
      total: response.data.bookings.residentRequests.total,
    },
    {
      name: "residentInvites",
      accepted: response.data.bookings.residentInvites.accepted,
      cancelled: response.data.bookings.residentInvites.cancelled,
      completed: response.data.bookings.residentInvites.completed,
      pending: response.data.bookings.residentInvites.pending,
      rejected: response.data.bookings.residentInvites.rejected,
      total: response.data.bookings.residentInvites.total,
    },
    {
      name: "residentRequests",
      accepted: response.data.bookings.residentRequests.accepted,
      cancelled: response.data.bookings.residentRequests.cancelled,
      completed: response.data.bookings.residentRequests.completed,
      pending: response.data.bookings.residentRequests.pending,
      rejected: response.data.bookings.residentRequests.rejected,
      total: response.data.bookings.residentRequests.total,
    },
    {
      name: "tattoos",
      accepted: response.data.bookings.tattoos.accepted,
      cancelled: response.data.bookings.tattoos.cancelled,
      completed: response.data.bookings.tattoos.completed,
      pending: response.data.bookings.tattoos.pending,
      rejected: response.data.bookings.tattoos.rejected,
      total: response.data.bookings.tattoos.total,
    },
    {
      name: "travels",
      accepted: response.data.bookings.travels.accepted,
      cancelled: response.data.bookings.travels.cancelled,
      completed: response.data.bookings.travels.completed,
      total: response.data.bookings.travels.total,
    },
  ]

  const payments = [
    {
      name: "payments",
      failed: response.data.bookings.tattoos.payment.failed,
      incomplete: response.data.bookings.tattoos.payment.incomplete,
      paid: response.data.bookings.tattoos.payment.paid,
      pending: response.data.bookings.tattoos.payment.pending,
      received: response.data.bookings.tattoos.payment.received,
    },
  ]

  // ACTIVITIES

  const activities = [
    {
      name: "activities",
      bookingReceived: response.data.activities.bookingReceived,
      bookingUpdated: response.data.activities.bookingUpdated,
      commentReceived: response.data.activities.commentReceived,
      followReceived: response.data.activities.followReceived,
      likeReceived: response.data.activities.likeReceived,
      messageReceived: response.data.activities.messageReceived,
      total: response.data.activities.total,
    },
  ]

  // PHOTOS

  const photos = [
    {
      name: "photos",
      collection: response.data.photos.collection,
      flash: response.data.photos.flash,
      tattoo: response.data.photos.tattoo,
      total: response.data.photos.total,
    },
  ]

  // HASHTAGS

  const hashtags = [
    {
      name: "hashtags",
      total: response.data.hashtags.total,
    },
  ]

  // SUBSCRIPTIONS

  const subscriptions = [
    {
      name: "subscriptions",
      total: response.data.subscriptions.total,
    },
  ]

  // COMMENTS

  const comments = [
    {
      name: "comments",
      total: response.data.comments.total,
    },
  ]

  return (
    <Layout>
      <SEO
        title={t("pages.Stats.seo.title")}
        description={t("pages.Stats.seo.description")}
      />
      <Section>
        <Container>
          <Heading>{t("pages.Stats.heading")}</Heading>

          <Heading renderAs={"h3"} size={4}>
            {t("pages.Stats.users")}
          </Heading>
          <ResponsiveContainer width={"100%"} aspect={4.0 / 1.0}>
            <BarChart data={users}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="active" fill="red" />
              <Bar dataKey="frozen" fill="green" />
              <Bar dataKey="inactive" fill="pink" />
              <Bar dataKey="instagram" fill="blue" />
              <Bar dataKey="paid" fill="purple" />
              <Bar dataKey="total" fill="black" />
              <Bar dataKey="unverified" fill="cyan" />
              <Bar dataKey="verified" fill="orange" />
            </BarChart>
          </ResponsiveContainer>

          <Heading renderAs={"h3"} size={4}>
            {t("pages.Stats.bookings")}
          </Heading>
          <ResponsiveContainer width={"100%"} aspect={4.0 / 1.0}>
            <BarChart data={bookings}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="accepted" fill="green" />
              <Bar dataKey="cancelled" fill="orange" />
              <Bar dataKey="completed" fill="pink" />
              <Bar dataKey="pending" fill="blue" />
              <Bar dataKey="rejected" fill="red" />
              <Bar dataKey="total" fill="black" />
            </BarChart>
          </ResponsiveContainer>

          <Heading renderAs={"h3"} size={4}>
            {t("pages.Stats.payments")}
          </Heading>
          <ResponsiveContainer width={"100%"} aspect={4.0 / 1.0}>
            <BarChart data={payments}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="received" fill="green" />
              <Bar dataKey="incomplete" fill="orange" />
              <Bar dataKey="paid" fill="pink" />
              <Bar dataKey="incomplete" fill="blue" />
              <Bar dataKey="failed" fill="red" />
              <Bar dataKey="total" fill="black" />
            </BarChart>
          </ResponsiveContainer>

          <Heading renderAs={"h3"} size={4}>
            {t("pages.Stats.activities")}
          </Heading>
          <ResponsiveContainer width={"100%"} aspect={4.0 / 1.0}>
            <BarChart data={activities}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="bookingReceived" fill="green" />
              <Bar dataKey="bookingUpdated" fill="orange" />
              <Bar dataKey="commentReceived" fill="pink" />
              <Bar dataKey="followReceived" fill="blue" />
              <Bar dataKey="likeReceived" fill="red" />
              <Bar dataKey="messageReceived" fill="purple" />
              <Bar dataKey="total" fill="black" />
            </BarChart>
          </ResponsiveContainer>

          <Heading renderAs={"h3"} size={4}>
            {t("pages.Stats.photos")}
          </Heading>
          <ResponsiveContainer width={"100%"} aspect={4.0 / 1.0}>
            <BarChart data={photos}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="collection" fill="blue" />
              <Bar dataKey="flash" fill="red" />
              <Bar dataKey="tattoo" fill="green" />
              <Bar dataKey="total" fill="black" />
            </BarChart>
          </ResponsiveContainer>

          <Heading renderAs={"h3"} size={4}>
            {t("pages.Stats.hashtags")}
          </Heading>
          <ResponsiveContainer width={"100%"} aspect={4.0 / 1.0}>
            <BarChart data={hashtags}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="total" fill="black" />
            </BarChart>
          </ResponsiveContainer>

          <Heading renderAs={"h3"} size={4}>
            {t("pages.Stats.subscriptions")}
          </Heading>
          <ResponsiveContainer width={"100%"} aspect={4.0 / 1.0}>
            <BarChart data={subscriptions}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="total" fill="black" />
            </BarChart>
          </ResponsiveContainer>

          <Heading renderAs={"h3"} size={4}>
            {t("pages.Stats.comments")}
          </Heading>
          <ResponsiveContainer width={"100%"} aspect={4.0 / 1.0}>
            <BarChart data={comments}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="total" fill="black" />
            </BarChart>
          </ResponsiveContainer>
        </Container>
      </Section>
    </Layout>
  )
}

export default Stats
